
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import HeaderTit from '@/components/HeaderTit.vue'

export default defineComponent({
  name: 'ImgPreView',
  components: { HeaderTit },
  setup() {
    const route = useRoute()
    const url = route.query.url
    if (url && typeof url === 'string') {
      return {
        url: process.env.VUE_APP_PROXY_TARGET + url
      }
    }
  }
  // props: {
  //   url: {
  //     required: true,
  //     type: String
  //   }
  // }
})
